import {
  Type,
} from '../util/cacheApi';
import * as cacheApi from '../util/cacheApi';

export const loadItemFromLocalStorage = (key: string) => {
  const locallyStoredItem = localStorage.getItem(key);
  return locallyStoredItem ? JSON.parse(locallyStoredItem) : undefined;
};

export const loadItemFromLocalCache = async (key: string, cacheName: string) => {
  const localData = loadItemFromLocalStorage(key);
  if (localData) {
    return localData;
  }
  const cachedData = await cacheApi.fetch(cacheName, key, Type.Json);
  if (cachedData) {
    return (typeof cachedData === 'string') ? Promise.resolve(JSON.parse(cachedData)) : cachedData;
  }
  return undefined;
};

export async function removeItemFromLocalStorage(key: string, cacheName: string) {
  localStorage.removeItem(key);
  await cacheApi.remove(key, cacheName);
}

export const saveItemToLocalStorage = (key: string, value: any, cacheName: string) => {
  if (typeof value === 'undefined' || !value) {
    return Promise.reject(new Error('No value was passed.'));
  }
  if (typeof value === 'string') {
    localStorage.setItem(key, value);
  }

  if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value));
  }
  return cacheApi.save(cacheName, key, value);
};
