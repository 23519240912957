import type { ApiUser } from '../../api/types';
import type { Connector } from '../../util/PostMessageConnector';
import { createConnector } from '../../util/PostMessageConnector';
import Deferred from '../../util/Deferred';
import type { TranslateMeApi } from '../../lib/translateme/translateme.worker';

const WORKER_INIT_DELAY = 4000;

let worker: Connector<TranslateMeApi> | undefined;
const initializationDeferred = new Deferred();

setTimeout(initWorker, WORKER_INIT_DELAY);

function initWorker() {
  if (!worker) {
    worker = createConnector<TranslateMeApi>(
      new Worker(new URL('../../lib/translateme/translateme.worker.ts', import.meta.url)),
    );
    initializationDeferred.resolve();
  }
}

export async function initTmnUser(currentUser: ApiUser) {
  if (!worker) await initializationDeferred.promise;
  return worker!.request({
    name: 'getUserDetails',
    args: [currentUser],
  });
}
