import type { Connector } from '../../util/PostMessageConnector';

import Deferred from '../../util/Deferred';

import { createConnector } from '../../util/PostMessageConnector';
import type { ChatGptAPI } from '../../lib/translateme/chatgpt.worker';
import type { TMNUser } from '../types';

const WORKER_INIT_DELAY = 4000;

let worker: Connector<ChatGptAPI> | undefined;
const initializationDeferred = new Deferred();

setTimeout(initWorker, WORKER_INIT_DELAY);

function initWorker() {
  if (!worker) {
    worker = createConnector<ChatGptAPI>(
      new Worker(new URL('../../lib/translateme/chatgpt.worker.ts', import.meta.url)),
    );
    initializationDeferred.resolve();
  }
}

export async function initGptChatsCache(cachedUser: TMNUser) {
  if (!cachedUser.id) {
    return [];
  }
  if (!worker) {
    await initializationDeferred.promise;
  }
  return worker!.request({
    name: 'getUserChatCache',
    args: [cachedUser],
  });
}

export async function sendPromptToOpenAi(cachedUser: TMNUser, prompt: string, chatId?: string) {
  if (!worker) await initializationDeferred.promise;
  return worker!.request({
    name: 'sendPromptToOpenAi',
    args: [cachedUser, prompt, chatId],
  });
}
