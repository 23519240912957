import { useCallback, useEffect, useState } from '../lib/teact/teact';
import type { TranslateMeConfiguration } from '../global/types';
import { getTranslationSettingsForChat } from './useGetTranslationSettingsForChat';
import { loadTranslateMeUser } from '../lib/translateMeUtilityFuncs';

export function useHasTMNTranslation(chatId: string | undefined) {
  const [isChatUsingTranslate, setIsChatUsingTranslate] = useState(false);
  const [activeClass, setActiveClass] = useState('');
  const [translateMeConfiguration, setTranslateMeConfiguration] = useState({
    useTranslateMe: false,
    whichProvider: 'tmn',
    languageConfig: {
      theirLanguage: {},
      myLanguage: {},
    },
  } as TranslateMeConfiguration);

  const changeSettings = useCallback((e) => {
    return setIsChatUsingTranslate(e);
  }, []);

  useEffect(() => {
    async function updateIfNotEnoughCreds(configuration: TranslateMeConfiguration) {
      const user = await loadTranslateMeUser();
      if (user) {
        const hasEnoughGoogleChars = user?.remaining_chars;
        const hasEnoughTmnChars = user?.translateme_remaining_chars;
        if (!hasEnoughGoogleChars && configuration.whichProvider === 'google') {
          setIsChatUsingTranslate(false);
        }

        if (!hasEnoughTmnChars && configuration.whichProvider === 'tmn') {
          setIsChatUsingTranslate(false);
        }
      }
    }

    async function callGetSettings() {
      const configuration = await getTranslationSettingsForChat(chatId);
      if (typeof configuration !== 'undefined') {
        const myLanguage = typeof configuration.languageConfig.myLanguage === 'string'
          ? JSON.parse(configuration.languageConfig.myLanguage)
          : configuration.languageConfig.myLanguage;
        const theirLanguage = typeof configuration.languageConfig.theirLanguage === 'string'
          ? JSON.parse(configuration.languageConfig.theirLanguage)
          : configuration.languageConfig.theirLanguage;
        setTranslateMeConfiguration({
          ...translateMeConfiguration,
          useTranslateMe: configuration.useTranslateMe,
          whichProvider: configuration.whichProvider,
          languageConfig: {
            theirLanguage,
            myLanguage,
          },
        });
        setIsChatUsingTranslate(configuration.useTranslateMe);
        setActiveClass(configuration.useTranslateMe ? 'translation-active' : '');
        await updateIfNotEnoughCreds(configuration);
        return;
      }

      setIsChatUsingTranslate(false);
      setTranslateMeConfiguration({
        ...translateMeConfiguration,
        useTranslateMe: false,
        whichProvider: 'tmn',
        languageConfig: {
          theirLanguage: {},
          myLanguage: {},
        },
      } as TranslateMeConfiguration);
      setActiveClass('');
    }
    callGetSettings();
  }, [chatId, isChatUsingTranslate, changeSettings, translateMeConfiguration]);
  return {
    isChatUsingTranslate, activeClass, translateMeConfiguration, changeSettings,
  };
}
