import { getLocalCreditsCount, loadTranslateMeUser } from '../lib/translateMeUtilityFuncs';
import {
  TRANSLATE_ME, TRANSLATE_ME_EMAIL, TRANSLATE_ME_PASSWORD,
} from '../config';
import type { TmnTranslationProviders, TMNUser } from '../global/types';
import { saveItemToLocalStorage } from '../hooks/useLocalStorage';
import type { ApiMessage } from '../api/types';

export async function translateMessageTmn(
  message: string,
  chatId: string,
  isTranslationActive: boolean,
  from?: string,
  to?: string,
  provider?: TmnTranslationProviders,
  apiMessage?: ApiMessage,
) {
  if (!isTranslationActive
    || !message
    || !(typeof from === 'string')
    || !(typeof to === 'string')
    || !provider) {
    return message;
  }
  const remainingCredits = await getLocalCreditsCount();
  if (remainingCredits.googleCredits < message.length && remainingCredits.tmnCredits < message.length) {
    return false;
  }

  // If the languages are identical - no need to call the API or do anything.
  if (from === to) {
    return message;
  }
  try {
    if (TRANSLATE_ME) {
      const cachedUser: TMNUser = await loadTranslateMeUser();
      const formData = new URLSearchParams();
      formData.append('action', 'translateMessage');
      formData.append('tmn_user_id', cachedUser.id);
      formData.append('text', JSON.stringify({ text: message }));
      formData.append('provider', provider);
      formData.append('from', from);
      formData.append('to', to);
      formData.append('app_email', String(TRANSLATE_ME_EMAIL));
      formData.append('app_id', String(TRANSLATE_ME_PASSWORD));
      if (apiMessage) {
        const chatKey = `B${chatId}`;
        const messageKey = `C${apiMessage?.id}`;
        formData.append('t_chat_id', chatKey);
        formData.append('t_message_id', messageKey);
        formData.append('telegram_user_id', cachedUser.telegram_user_id);
      }
      try {
        const response = await fetch(TRANSLATE_ME, {
          method: 'POST',
          body: formData,
        });

        if (!response?.ok) {
          return false;
        }

        const data = await response.json();
        if (Number(data.status) === 200) {
          if (data.cache && data.cache === true) {
            return { translatedText: data.to, fromCache: true };
          }
          cachedUser.remaining_chars = data.remaining_chars;
          cachedUser.consumed_chars = data.consumed_chars;
          cachedUser.translateme_remaining_chars = data.translateme_remaining_chars;
          cachedUser.translateme_consumed_chars = data.translateme_consumed_chars;
          await saveItemToLocalStorage('translateMeRegisteredUser', cachedUser, 'TranslateMeWebsiteCache');
          return { translatedText: data.to, isTranslated: true, fromCache: false };
        } else {
          return { translatedText: 'Error, could not translate message.', isTranslated: false, fromCache: false };
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
      return message;
    }
    return message;
  } catch (e) {
    // If the translation server is down - return the original text and continue.
    return message;
  }
}
