import { loadItemFromLocalCache, loadItemFromLocalStorage } from './useLocalStorage';
import type { TranslateMeConfiguration } from '../global/types';

function processConfigs(existingConfigs: any, chatId?: string) {
  if (!existingConfigs) {
    return {
      useTranslateMe: false,
      whichProvider: 'tmn',
      languageConfig: {
        myLanguage: {},
        theirLanguage: {},
      },
    } as TranslateMeConfiguration;
  }

  const key = `A${chatId}`;
  return existingConfigs.hasOwnProperty(key) ? existingConfigs[key] : undefined;
}
export async function getTranslationSettingsForChat(chatId?: string) {
  if (!chatId) {
    return undefined;
  }

  let existingConfigs;

  existingConfigs = await loadItemFromLocalStorage('translateMeSettingsByChat');
  if (existingConfigs) {
    const key = `A${chatId}`;
    if (existingConfigs[key]) {
      if (typeof existingConfigs[key].languageConfig.myLanguage === 'string') {
        existingConfigs[key].languageConfig.myLanguage = JSON.parse(existingConfigs[key].languageConfig.myLanguage);
      }

      if (typeof existingConfigs[key].languageConfig.theirLanguage === 'string') {
        existingConfigs[key]
          .languageConfig
          .theirLanguage = JSON.parse(existingConfigs[key].languageConfig.theirLanguage);
      }
      return existingConfigs[key];
    }
    return undefined;
  }

  existingConfigs = await loadItemFromLocalCache('translateMeSettingsByChat', 'languagesByChat');
  const processedConfigs = await processConfigs(existingConfigs, chatId);
  return processedConfigs as TranslateMeConfiguration;
}
