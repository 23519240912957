import { loadItemFromLocalCache } from '../hooks/useLocalStorage';
import { TRANSLATE_ME } from '../config';
import { loadTranslateMeUser } from '../lib/translateMeUtilityFuncs';

export async function loadTranslationFromLocalCache(
  messageId: string | number, chatId: string | number,
) {
  const messagesCache = await loadItemFromLocalCache('translatedMessagesCache', 'translatedMessagesCache');
  const chatKey = `B${chatId}`;
  const messageKey = `C${messageId}`;
  const mainKey = `${chatKey}__${messageKey}`;
  if (!messagesCache) {
    return false;
  }
  if (messagesCache[mainKey] && messagesCache[mainKey][messageKey]) {
    return messagesCache[mainKey][messageKey].messageText;
  }

  return false;
}

function compareStrings(str1: string, str2: string) {
  // Normalize the strings using Unicode normalization
  const normalizedStr1 = str1.normalize();
  const normalizedStr2 = str2.normalize();

  // Compare the normalized strings
  return normalizedStr1 === normalizedStr2;
}

export async function saveTranslatedMessageToLocalCache(
  messageId: string | number, chatId: string | number, translatedMessage: string, originalText: string,
) {
  const cachedUser = await loadTranslateMeUser();
  const formData = new FormData();
  const chatKey = `B${chatId}`;
  const messageKey = `C${messageId}`;
  if (compareStrings(originalText, translatedMessage)) {
    // Don't save cache if the text is the same.
    return;
  }
  formData.append('action', 'saveTranslatedTextToCache');
  formData.append('tmn_user_id', cachedUser.id);
  formData.append('telegram_user_id', cachedUser.telegram_user_id);
  formData.append('t_chat_id', chatKey);
  formData.append('t_message_id', messageKey);
  formData.append('original_text', originalText);
  formData.append('translated_message', translatedMessage);
  await fetch(`${TRANSLATE_ME}`, {
    method: 'POST',
    body: formData,
  });
}
