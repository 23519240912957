// @ts-ignore
import {
  translate,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Translator,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  singleTranslate,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBatchInitData,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  batchTranslate,
  // @ts-ignore
  languages as googleLanguages,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSupported,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCode,
} from 'google-translate-api-x';
import {
  loadItemFromLocalCache,
  loadItemFromLocalStorage,
  removeItemFromLocalStorage,
  saveItemToLocalStorage,
} from '../hooks/useLocalStorage';
import { getGlobal } from '../global';
import { publish } from '../hooks/useCustomEvents';
import * as translated_languages from '../util/languages.json';
import type { Language, TMNUser, ChatGptMessage } from '../global/types';
import { tmnLanguages } from '../util/translateMeLanguages';

const generalLanguageListWithTranslations = [] as any;
Object.entries(translated_languages)
  .forEach((pair) => {
    const [code, languageData] = pair;
    generalLanguageListWithTranslations[code] = languageData;
  });

export async function loadTranslateMeUser() {
  let cachedUser;
  cachedUser = loadItemFromLocalStorage('translateMeRegisteredUser');
  if (!cachedUser) {
    cachedUser = await loadItemFromLocalCache('translateMeRegisteredUser', 'TranslateMeWebsiteCache');
  }
  getGlobal().tmnUser = cachedUser;
  return cachedUser;
}

export async function updateCachedCredits(cachedUser: TMNUser) {
  await saveItemToLocalStorage('translateMeRegisteredUser', cachedUser, 'TranslateMeWebsiteCache');
  publish('updateChars', cachedUser);
}

export async function deductCreditsFromUser(cachedUser: TMNUser) {
  await saveItemToLocalStorage('translateMeRegisteredUser', cachedUser, 'TranslateMeWebsiteCache');
  publish('deductChars', {
    remainingGoogleChars: cachedUser.remaining_chars,
    remainingTmnChars: cachedUser.translateme_remaining_chars,
    remainingAiTokens: cachedUser.ai_tokens,
    totalConsumedAiTokens: cachedUser.consumed_ai_tokens,
  });
}

export async function getLocalCreditsCount() {
  const cachedUser = await loadTranslateMeUser();
  return {
    googleCredits: cachedUser.remaining_chars,
    tmnCredits: cachedUser.translateme_remaining_chars,
    aiTokens: cachedUser.ai_tokens,
  };
}

export function buildOptions(languages: Language[]) {
  const currentLangCode = (window.navigator.language || 'en').toLowerCase();
  const shortLangCode = currentLangCode.slice(0, 2);

  return languages.map(({
    langCode,
    nativeName,
    name,
    flag,
    defaultName,
  }) => ({
    value: langCode,
    label: nativeName,
    subLabel: name,
    defaultName,
    flag,
  }))
    .sort((a) => {
      return currentLangCode && (a.value === currentLangCode || a.value === shortLangCode) ? -1 : 0;
    });
}

function generateLanguageListTmn() {
  const languageList: Language[] = [];

  Object.values(tmnLanguages)
    .forEach((language) => {
      languageList.push({
        iso2: language.iso2,
        langCode: language.langCode,
        name: generalLanguageListWithTranslations[language.iso2].input,
        nativeName: generalLanguageListWithTranslations[language.iso2].text || language.nativeName,
        defaultName: language.defaultName,
        flag: language.iso2.toUpperCase(),
        selected: false,
      });
    });
  return languageList;
}

function generateLanguageListGoogle() {
  const languageList: Language[] = [];

  Object.keys(googleLanguages)
    .forEach((key: string) => {
      if (key !== 'auto') {
        const exists = generalLanguageListWithTranslations[key];
        if (exists) {
          languageList.push({
            iso2: key,
            langCode: key,
            name: generalLanguageListWithTranslations[key].input,
            nativeName: generalLanguageListWithTranslations[key].text,
            // @ts-ignore
            defaultName: translate.languages[key],
            flag: key.toUpperCase(),
            selected: false,
          });
        }
      }
    });
  return languageList;
}

export function generateLanguageList(which: string) {
  switch (which) {
    case 'google':
      return generateLanguageListGoogle();
    case 'tmn':
      return generateLanguageListTmn();
    default:
      return [];
  }
}

export function decodeHTMLEntities(text: string) {
  const entities = [
    ['amp', '&'],
    ['apos', "'"],
    ['lt', '<'],
    ['gt', '>'],
    ['quot', '"'],
    ['#39', "'"],
    ['#34', '"'],
    ['#60', '<'],
    ['#62', '>'],
    ['#10', '\n'],
  ];

  for (const [entity, character] of entities) {
    const regex = new RegExp(`&${entity};`, 'g');
    text = text.replace(regex, character);
  }

  return text;
}

export function saveChatToLocalCache(chatId: string, transcript: string | object) {
  saveItemToLocalStorage('openAiLastChat', JSON.stringify({ chatId, transcript }), 'openAiLastChat');
}

export async function loadLastAiChat() {
  const lastChat = await loadItemFromLocalCache('openAiLastChat', 'openAiLastChat');
  return lastChat || undefined;
}

export function removeLastChatCache() {
  removeItemFromLocalStorage('openAiLastChat', 'openAiLastChat');
}

export async function loadAiChatsCache() {
  const chatsCache = await loadItemFromLocalCache('chatGptCache', 'chatGptCache');
  return chatsCache || [];
}

export function saveChatGptCache(chatsCache: any) {
  saveItemToLocalStorage('chatGptCache', chatsCache, 'chatGptCache');
}

export function downloadChatTranscript(jsonData: ChatGptMessage[], fileName: string): void {
  let formattedText = '';
  // Format the JSON content
  for (const item of jsonData) {
    const speaker = item.role === 'user' ? 'You' : 'AI';
    formattedText += `${speaker} Said: ${item.content}\n\n`;
  }

  // Create a new Blob object with the formatted text data
  const blob = new Blob([formattedText], { type: 'text/plain' });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element and set its properties
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;

  // Simulate a click event to trigger the download
  link.click();

  // Clean up the temporary URL and remove the link element
  URL.revokeObjectURL(url);
  link.remove();
}
