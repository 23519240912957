import type { Language } from '../global/types';

export const tmnLanguages: Language[] = [
  {
    name: 'English',
    defaultName: 'English',
    nativeName: 'English',
    iso2: 'en',
    langCode: 'en',
  },
  {
    name: 'French',
    defaultName: 'French',
    nativeName: 'Français',
    iso2: 'fr',
    langCode: 'fr',
  },
  {
    name: 'German',
    defaultName: 'German',
    nativeName: 'Deutsch',
    iso2: 'de',
    langCode: 'de',
  },
  {
    name: 'Russian',
    defaultName: 'Russian',
    nativeName: 'Русский',
    iso2: 'ru',
    langCode: 'ru',
  },
  {
    name: 'Spanish',
    defaultName: 'Spanish',
    nativeName: 'Español',
    iso2: 'es',
    langCode: 'es',
  },
  {
    name: 'Portuguese',
    defaultName: 'Portuguese',
    nativeName: 'Português',
    iso2: 'pt',
    langCode: 'pt',
  },
  {
    name: 'Zulu',
    defaultName: 'Zulu',
    nativeName: 'IsiZulu',
    iso2: 'zu',
    langCode: 'zu',
  },
  {
    name: 'Dutch',
    defaultName: 'Dutch',
    nativeName: 'Nederlands',
    iso2: 'nl',
    langCode: 'nl',
  },
  {
    name: 'Italian',
    defaultName: 'Italian',
    nativeName: 'Italiano',
    iso2: 'it',
    langCode: 'it',
  },
  {
    name: 'Indonesian',
    defaultName: 'Indonesian',
    nativeName: 'Bahasa Melayu',
    iso2: 'id',
    langCode: 'id',
  },
  {
    name: 'Japanese',
    defaultName: 'Japanese',
    nativeName: '日本語',
    iso2: 'ja',
    langCode: 'ja',
  },
  {
    name: 'Chinese',
    defaultName: 'Chinese',
    nativeName: 'Chinese',
    iso2: 'ch',
    langCode: 'ch',
  },
  {
    name: 'Korean',
    defaultName: 'Korean',
    nativeName: '한국어',
    iso2: 'ko',
    langCode: 'ko',
  },
  {
    name: 'Hindi',
    defaultName: 'Hindi',
    nativeName: 'मानक हिन्दी',
    iso2: 'hi',
    langCode: 'hi',
  },
  {
    name: 'Arabic',
    defaultName: 'Arabic',
    nativeName: 'اَلْعَرَبِيَّةُ',
    iso2: 'ar',
    langCode: 'ar',
  },
  {
    name: 'Afrikaans',
    defaultName: 'Afrikaans',
    nativeName: 'Afrikaans',
    iso2: 'af',
    langCode: 'af',
  },
  {
    name: 'Ukrainian',
    defaultName: 'Ukrainian',
    nativeName: 'Украї́нська',
    iso2: 'uk',
    langCode: 'uk',
  },
  {
    name: 'Turkish',
    defaultName: 'Turkish',
    nativeName: 'Turkish Türkçe',
    iso2: 'tr',
    langCode: 'tr',
  },
  {
    name: 'Azerbaijani',
    defaultName: 'Azerbaijani',
    nativeName: 'Azerbaijani',
    iso2: 'az',
    langCode: 'az',
  },
  {
    name: 'Czech',
    defaultName: 'Czech',
    nativeName: 'Czech Čeština',
    iso2: 'cs',
    langCode: 'cs',
  },
  {
    name: 'Danish',
    defaultName: 'Danish',
    nativeName: 'Dansk',
    iso2: 'da',
    langCode: 'da',
  },
  {
    name: 'Finnish',
    defaultName: 'Finnish',
    nativeName: 'Suomi',
    iso2: 'fi',
    langCode: 'fi',
  },
  {
    name: 'Greek',
    defaultName: 'Greek',
    nativeName: 'Ελληνικά',
    iso2: 'el',
    langCode: 'el',
  },
  {
    name: 'Esperanto',
    defaultName: 'Esperanto',
    nativeName: 'Esperanto',
    iso2: 'eo',
    langCode: 'eo',
  },
  {
    name: 'Hungarian',
    defaultName: 'Hungarian',
    nativeName: 'Magyar',
    iso2: 'hu',
    langCode: 'hu',
  },
  {
    name: 'Irish',
    defaultName: 'Irish',
    nativeName: 'Gaeilge',
    iso2: 'ga',
    langCode: 'ga',
  },
  {
    name: 'Persian',
    defaultName: 'Persian',
    nativeName: '',
    iso2: 'fa',
    langCode: 'fa',
  },
  {
    name: 'Slovak',
    defaultName: 'Slovak',
    nativeName: 'Slovenčina',
    iso2: 'sk',
    langCode: 'sk',
  },
];
