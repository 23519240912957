import { useEffect, useMemo, useState } from '../lib/teact/teact';
import * as translated_phrases from '../util/translations.json';

export function useTmnTranslatedPhrases() {
  const translationsObject = useMemo(() => {
    return {
      SelectOutGoingMsgLang: 'Select Language to translate outgoing messages to:',
      SelectIncomingMsgLang: 'Select Language to translate incoming messages to:',
      TranslateMySengMsgTo: 'Translate MY SENT messages to',
      TranslateGrpSengMsgTo: 'Translate group messages to',
      AutoTrsGoogleApi: 'Auto Translate Google API',
      SelectLanguage: 'Select Language',
      SaveTheirLang: 'Save Outgoing Messages Language',
      SaveMyLang: 'Save Incoming Messages Language',
      EnableTranslation: 'Enable Translation',
      Loading: 'Loading...',
      Translating: 'Translating...',
      SelectBothLanguageFromSameProvider: 'You must select both languages from the same provider.',
      NotEnoughCreds: 'You do not have enough credits to complete this action, please purchase more through our app.',
      EmptyPrompt: 'You must type a prompt for the AI to respond to',
      NotEnoughAiTokens: 'Not Enough tokens, please purchase more at ',
      LetsStart: 'Let\'s start our conversation!',
      EnterPrompt: 'Enter a prompt for our AI Assistant',
      Thinking: 'Thinking...',
      BuyMoreCredits: 'Buy more credits',
      MoreCredsExplained: 'Buy more credits to use with Translate Me and Google Translate.',
      OpenAiError: 'Dear user, unfortunately we have encountered an error. Please try again later.',
      NoChatHistory: 'There is no chat history',
    };
  }, []);

  const [phrases, setPhrases] = useState(translationsObject);

  useEffect(() => {
    const currentLangCode = (window.navigator.language || 'en').toLowerCase();
    Object.entries(translated_phrases).forEach((pair) => {
      const [code, translationData] = pair;
      if (currentLangCode.slice(0, 2) === code) {
        translationData.forEach((val) => {
          switch (val.input) {
            case 'Select Language to translate outgoing messages to:':
              translationsObject.SelectOutGoingMsgLang = val.text;
              break;
            case 'Select Language to translate incoming messages to:':
              translationsObject.SelectIncomingMsgLang = val.text;
              break;
            case 'Translate MY SENT messages to':
              translationsObject.TranslateMySengMsgTo = val.text;
              break;
            case 'Translate group messages to':
              translationsObject.TranslateGrpSengMsgTo = val.text;
              break;
            case 'Auto Translate Google API':
              translationsObject.AutoTrsGoogleApi = val.text;
              break;
            case 'Select Language':
              translationsObject.SelectLanguage = val.text;
              break;
            case 'Save Outgoing Messages Language':
              translationsObject.SaveTheirLang = val.text;
              break;
            case 'Save Incoming Messages Language':
              translationsObject.SaveMyLang = val.text;
              break;
            default:
              break;
          }
        });
      }
    });
    setPhrases(translationsObject);
  }, [translationsObject]);

  return phrases;
}
