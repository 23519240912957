import type { FC } from '../../../lib/teact/teact';
import React, { memo } from '../../../lib/teact/teact';
import { Bundles } from '../../../util/moduleLoader';
import useModuleLoader from '../../../hooks/useModuleLoader';
import type { OwnProps } from './SelectLanguageModal';

const SelectLanguageModalAsync: FC<OwnProps> = (props) => {
  const { isOpen } = props;
  const SelectLanguageModal = useModuleLoader(Bundles.Extra, 'SelectLanguageModal', !isOpen);
  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  return SelectLanguageModal ? <SelectLanguageModal {...props} /> : undefined;
};

export default memo(SelectLanguageModalAsync);
