import React, {
  memo, useCallback, useMemo, useRef, useState,
} from '../../lib/teact/teact';

import type { ApiFormattedText, ApiMessage } from '../../api/types';
import type { ObserveFn } from '../../hooks/useIntersectionObserver';

import { ApiMessageEntityTypes } from '../../api/types';
import trimText from '../../util/trimText';
import { extractMessageText, getMessageText, stripCustomEmoji } from '../../global/helpers';
import { renderTextWithEntities } from './helpers/renderTextWithEntities';
import Button from '../ui/Button';
// eslint-disable-next-line import/no-cycle
import useTranslation from '../../hooks/useTranslation';

import { selectChat, selectTheme } from '../../global/selectors';
import { getActions, getGlobal } from '../../global';
import useSyncEffect from '../../hooks/useSyncEffect';
import useLang from '../../hooks/useLang';
import { getLocalCreditsCount } from '../../lib/translateMeUtilityFuncs';
import { useHasTMNTranslation } from '../../hooks/useHasTMNTranslation';
import { translateMessageTmn } from '../../util/translateMessageTmn';
import Spinner from '../ui/Spinner';
import { saveTranslatedMessageToLocalCache, loadTranslationFromLocalCache } from '../../util/localMessagesCacheService';
import { useTmnTranslatedPhrases } from '../../hooks/useTmnTranslatedPhrases';
import { publish } from '../../hooks/useCustomEvents';
import { detectLanguage } from '../../util/languageDetection';

interface OwnProps {
  message: ApiMessage;
  translatedText?: ApiFormattedText;
  isForAnimation?: boolean;
  emojiSize?: number;
  highlight?: string;
  isSimple?: boolean;
  truncateLength?: number;
  isProtected?: boolean;
  observeIntersectionForLoading?: ObserveFn;
  observeIntersectionForPlaying?: ObserveFn;
  withTranslucentThumbs?: boolean;
  shouldRenderAsHtml?: boolean;
  inChatList?: boolean;
}

const MIN_CUSTOM_EMOJIS_FOR_SHARED_CANVAS = 3;

function MessageText({
  message,
  translatedText,
  isForAnimation,
  emojiSize,
  highlight,
  isSimple,
  truncateLength,
  isProtected,
  observeIntersectionForLoading,
  observeIntersectionForPlaying,
  withTranslucentThumbs,
  shouldRenderAsHtml,
  inChatList,
}: OwnProps) {
  // eslint-disable-next-line no-null/no-null
  const sharedCanvasRef = useRef<HTMLCanvasElement>(null);
  // eslint-disable-next-line no-null/no-null
  const sharedCanvasHqRef = useRef<HTMLCanvasElement>(null);
  const global = getGlobal();
  const chat = selectChat(getGlobal(), message.chatId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    isChatUsingTranslate,
    translateMeConfiguration,
  } = useHasTMNTranslation(String(message.chatId));

  const [translateBtnDisabled, setTranslateBtnDisabled] = useState(false);
  const [hasTranslation, setHasTranslation] = useState(false);
  const [translatedTextValue, setTranslatedTextValue] = useState('');
  const [purchaseMoreCreds, setPurchaseMoreCreds] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const theme = selectTheme(global);
  const color = theme === 'light' ? 'black' : 'white';
  const backgroundColor = theme === 'light' ? undefined : 'dark';
  const phrases = useTmnTranslatedPhrases();
  const handleTranslationModalCancel = useCallback(() => {
    function onClose() {
    }

    onClose();
  }, []);
  // eslint-disable-next-line max-len
  const [requestTranslation, translationModal] = useTranslation(chat, message.chatId, handleTranslationModalCancel);
  const textCacheBusterRef = useRef(0);

  const formattedText = translatedText || extractMessageText(message, inChatList);
  const lang = useLang();
  const adaptedFormattedText = isForAnimation && formattedText ? stripCustomEmoji(formattedText) : formattedText;
  const { text, entities } = adaptedFormattedText || {};
  const translateChatMessage = useCallback(async () => {
    // Don't bother with anything without valid text.
    if (!text) {
      setTranslateBtnDisabled(false);
      return;
    }

    if (!('useTranslateMe' in translateMeConfiguration)) {
      setTranslateBtnDisabled(true);
      requestTranslation(() => {
      });
      setTranslateBtnDisabled(false);
      return;
    }

    const languages = translateMeConfiguration.languageConfig;

    if (!languages || !languages.myLanguage || !languages.theirLanguage) {
      setTranslateBtnDisabled(true);
      requestTranslation(() => {});
      setTranslateBtnDisabled(false);
      return;
    }

    const remainingCredits = await getLocalCreditsCount();
    if (remainingCredits.googleCredits < text.length && remainingCredits.tmnCredits < text.length) {
      setPurchaseMoreCreds(true);
      setTranslateBtnDisabled(true);
    }
    setIsLoading(true);

    const messagesCache = await loadTranslationFromLocalCache(message.id, message.chatId);
    if (messagesCache) {
      const div = document.createElement('div');
      div.innerHTML = messagesCache;
      setTranslatedTextValue(String(div.textContent));
      setIsLoading(false);
      setHasTranslation(true);
      return;
    }

    const detectedLanguage = await detectLanguage(text);
    let theirLanguage = translateMeConfiguration.languageConfig.theirLanguage?.iso2;
    if (detectedLanguage && detectedLanguage !== theirLanguage) {
      theirLanguage = detectedLanguage;
    }
    const translationResult = await translateMessageTmn(
      text,
      message.chatId,
      translateMeConfiguration.useTranslateMe,
      theirLanguage,
      translateMeConfiguration.languageConfig.myLanguage?.iso2,
      translateMeConfiguration.whichProvider,
      message,
    );
    if (translationResult === false) {
      getActions().showDialog?.({ data: { message: 'NOT_ENOUGH_CREDITS', hasErrorKey: true } });
      setPurchaseMoreCreds(true);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    if (typeof translationResult === 'string') {
      setTranslatedTextValue(translationResult);
      await saveTranslatedMessageToLocalCache(message.id, message.chatId, translationResult, text);
    } else if (typeof translationResult === 'object') {
      setTranslatedTextValue(translationResult.translatedText);
      if (translationResult.hasOwnProperty('cache') && !translationResult.fromCache) {
        await saveTranslatedMessageToLocalCache(message.id, message.chatId, translationResult.translatedText, text);
      }
    }
    setHasTranslation(true);
    setTranslateBtnDisabled(false);
    publish('updateChars', {});
  }, [text, translateMeConfiguration, message, requestTranslation]);
  const configureTranslation = useCallback(() => {
    requestTranslation(() => {
      if (message.content.text) {
        message.content.text.translatedValue = translatedTextValue;
      }
    });
  }, [message.content, requestTranslation, translatedTextValue]);

  useSyncEffect(() => {
    textCacheBusterRef.current += 1;
  }, [text, entities]);

  const withSharedCanvas = useMemo(() => {
    const hasSpoilers = entities?.some((e) => e.type === ApiMessageEntityTypes.Spoiler);
    if (hasSpoilers) {
      return false;
    }

    const customEmojisCount = entities?.filter((e) => e.type === ApiMessageEntityTypes.CustomEmoji).length || 0;
    return customEmojisCount >= MIN_CUSTOM_EMOJIS_FOR_SHARED_CANVAS;
  }, [entities]) || 0;

  if (!text) {
    const contentNotSupportedText = getMessageText(message);
    return contentNotSupportedText ? [trimText(contentNotSupportedText, truncateLength)] : undefined as any;
  }

  function renderLink() {
    const currentLang = navigator.language;
    let [hl, gl] = currentLang.split('-');
    if (!hl) {
      hl = 'en';
    }

    if (!gl) {
      gl = 'US';
    }

    return (
      <div className="translated-text-area">
        <a
          href={`https://play.google.com/store/apps/details?id=org.telegram.VicMacmessenger&hl=${hl}&gl=${gl}`}
          target="_blank"
          rel="noreferrer"
        >
          {phrases.NotEnoughCreds}
        </a>
      </div>
    );
  }

  const showTranslationArea = () => !message.isOutgoing && !truncateLength;
  return (
    <>
      {translationModal}
      {[
        withSharedCanvas && <canvas ref={sharedCanvasRef} className="shared-canvas" />,
        withSharedCanvas && <canvas ref={sharedCanvasHqRef} className="shared-canvas" />,
        renderTextWithEntities({
          text: trimText(text!, truncateLength),
          entities,
          highlight,
          emojiSize,
          shouldRenderAsHtml,
          messageId: message.id,
          isSimple,
          isProtected,
          observeIntersectionForLoading,
          observeIntersectionForPlaying,
          withTranslucentThumbs,
          sharedCanvasRef,
          sharedCanvasHqRef,
          cacheBuster: textCacheBusterRef.current.toString(),
        }),
      ].flat().filter(Boolean)}
      {showTranslationArea()
        && loading
        && (
          <div className="loading-area">
            <Spinner color={color} backgroundColor={backgroundColor} /> {phrases.Translating}
          </div>
        )}
      {showTranslationArea() && (
        <div className="translation-area-wrapper" dir="auto">
          <div className="translated-text-area display-linebreak">{[
            withSharedCanvas && <canvas ref={sharedCanvasRef} className="shared-canvas" />,
            withSharedCanvas && <canvas ref={sharedCanvasHqRef} className="shared-canvas" />,
            renderTextWithEntities({
              text: trimText(translatedTextValue!, truncateLength),
              entities,
              highlight,
              emojiSize,
              shouldRenderAsHtml,
              messageId: message.id,
              isSimple,
              isProtected,
              observeIntersectionForLoading,
              observeIntersectionForPlaying,
              withTranslucentThumbs,
              sharedCanvasRef,
              sharedCanvasHqRef,
              cacheBuster: textCacheBusterRef.current.toString(),
            }),
          ].flat().filter(Boolean)}
          </div>
          {purchaseMoreCreds && renderLink()}
          <div className="translation-area" dir="auto">
            {!hasTranslation && isChatUsingTranslate && (
              <Button
                faded
                isRtl={false}
                size="smaller"
                ariaLabel={lang('TranslateMessage')}
                disabled={translateBtnDisabled && !purchaseMoreCreds}
                onClick={translateChatMessage}
                className="translate-text-btn"
              >
                <i className="icon icon-language" /> {lang('TranslateMessage')}
              </Button>
            )}
            {!hasTranslation && isChatUsingTranslate && (
              <Button
                round
                faded
                color="translucent"
                size="tiny"
                ariaLabel="Settings"
                onClick={configureTranslation}
                className="translate-settings-btn"
              >
                <span className="material-icons md-18">settings</span>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default memo(MessageText);
