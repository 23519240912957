import { loadItemFromLocalCache, loadItemFromLocalStorage, saveItemToLocalStorage } from './useLocalStorage';
import type { LanguageConfig, TMNUser } from '../global/types';
import { TRANSLATE_ME } from '../config';

const CACHE_LIFETIME_MS = 300000; // five minutes in MS.

export async function saveTranslateMeForChatUse(
  chatId: string,
  useTranslateMe: boolean,
  newConfig: LanguageConfig,
  whichProvider: string,
  cachedUser: TMNUser,
) {
  if (!newConfig?.myLanguage || !newConfig?.theirLanguage || !whichProvider) {
    return {
      useTranslateMe: false,
    };
  }

  const key = `A${chatId}`;
  const formData = new FormData();
  formData.append('action', 'saveTranslationSettingsPerChat');
  formData.append('tmn_user_id', cachedUser.id);
  formData.append('chatId', key);
  formData.append('theirLanguage', JSON.stringify(newConfig?.theirLanguage));
  formData.append('myLanguage', JSON.stringify(newConfig?.myLanguage));
  formData.append('is_chat_using_translate', useTranslateMe ? '1' : '0');
  formData.append('whichProvider', String(whichProvider));
  const response = await fetch(`${TRANSLATE_ME}`, {
    method: 'POST',
    body: formData,
  });

  if (!response?.ok) {
    return {
      useTranslateMe: false,
    };
  }

  const configurationData = await response.json();

  if (!configurationData.success) {
    return {
      useTranslateMe: false,
    };
  }

  let existingConfigs;
  existingConfigs = loadItemFromLocalStorage('translateMeSettingsByChat');

  if (!existingConfigs) {
    existingConfigs = await loadItemFromLocalCache('translateMeSettingsByChat', 'languagesByChat');
  }

  if (!existingConfigs) {
    existingConfigs = {};
  }

  existingConfigs.expired_at = Date.now() + CACHE_LIFETIME_MS;
  existingConfigs[key] = { useTranslateMe, languageConfig: newConfig, whichProvider };
  await saveItemToLocalStorage('translateMeSettingsByChat', existingConfigs, 'languagesByChat');
  return existingConfigs[key];
}
