import React, { useCallback, useState } from '../lib/teact/teact';

import {
  loadItemFromLocalCache,
  loadItemFromLocalStorage,
  saveItemToLocalStorage,
} from './useLocalStorage';

import { getGlobal } from '../global';
import type { ApiChat } from '../api/types';
import { publish } from './useCustomEvents';
import { generateLanguageList, loadTranslateMeUser } from '../lib/translateMeUtilityFuncs';
import { saveTranslateMeForChatUse } from './useSaveTranslateMeForChatUse';
import { useHasTMNTranslation } from './useHasTMNTranslation';
import type { OnTranslationCallback } from '../global/types';
import { AllowedTranslateMeProviders } from '../global/types';
import SelectLanguageModalAsync from '../components/modals/tmnTranslation/SelectLanguageModal.async';

export async function deleteSettingsForChatUse(chatId: string | undefined) {
  let existingConfigs;

  existingConfigs = await loadItemFromLocalStorage('translateMeSettingsByChat');
  if (existingConfigs) {
    const key = `A${chatId}`;
    delete existingConfigs[key];
    await saveItemToLocalStorage('translateMeSettingsByChat', existingConfigs, 'languagesByChat');
    return;
  }

  existingConfigs = await loadItemFromLocalCache('translateMeSettingsByChat', 'languagesByChat');
  const key = `A${chatId}`;
  delete existingConfigs[key];
  await saveItemToLocalStorage('translateMeSettingsByChat', existingConfigs, 'languagesByChat');
}

const useTranslation = (
  chat?: ApiChat,
  chatId?: string,
  onCancel?: () => void,
) => {
  const [onScheduled, setOnScheduled] = useState<OnTranslationCallback | undefined>();
  const [resetModal, setResetModal] = useState<number>(0);
  const global = getGlobal();
  const {
    translateMeConfiguration,
    changeSettings,
  } = useHasTMNTranslation(String(chatId));

  const googleLangs = generateLanguageList('google');
  const tmnLangs = generateLanguageList('tmn');

  const handleCloseModal = useCallback(() => {
    setOnScheduled(undefined);
    setResetModal((prev) => prev + 1);
    onCancel?.();
  }, [onCancel]);

  const onSubmit = useCallback(async (mySelectedLang, theirSelectedLang) => {
    translateMeConfiguration.useTranslateMe = false;
    const whichProvider = mySelectedLang.whichProvider;
    translateMeConfiguration.whichProvider = whichProvider;
    if (!Object.keys(AllowedTranslateMeProviders).includes(whichProvider)) {
      if (tmnLangs.find((tmnLang) => tmnLang.iso2 === mySelectedLang.iso2)) {
        translateMeConfiguration.whichProvider = 'tmn';
      }

      if (googleLangs.find((googleLang) => googleLang.iso2 === mySelectedLang.iso2)) {
        translateMeConfiguration.whichProvider = 'google';
      }
    }

    translateMeConfiguration.languageConfig.myLanguage = generateLanguageList(whichProvider)
      .find((language) => language.iso2 === mySelectedLang.iso2);

    translateMeConfiguration.languageConfig.theirLanguage = generateLanguageList(whichProvider)
      .find((language) => language.iso2 === theirSelectedLang.iso2);

    if (whichProvider === 'google') {
      translateMeConfiguration.useTranslateMe = global.tmnUser.remaining_chars > 0;
    }

    if (whichProvider === 'tmn') {
      translateMeConfiguration.useTranslateMe = global.tmnUser.translateme_remaining_chars > 0;
    }
    const cachedUser = await loadTranslateMeUser();
    const savedConfigs = await saveTranslateMeForChatUse(
      String(chatId),
      translateMeConfiguration.useTranslateMe,
      translateMeConfiguration.languageConfig,
      translateMeConfiguration.whichProvider,
      cachedUser,
    );

    changeSettings(translateMeConfiguration.useTranslateMe);
    publish('tmnUpdate', { useTranslateMe: savedConfigs.useTranslateMe });
  }, [
    translateMeConfiguration,
    chatId,
    changeSettings,
    tmnLangs,
    googleLangs,
    global.tmnUser,
  ]);

  const requestTranslation = useCallback((whenScheduled: OnTranslationCallback) => {
    setOnScheduled(() => whenScheduled);
  }, []);

  const translationModal = (
    <SelectLanguageModalAsync
      isOpen={Boolean(onScheduled)}
      onClose={handleCloseModal}
      onSubmit={onSubmit}
      googleLangs={googleLangs}
      tmnLangs={tmnLangs}
      translateMeConfiguration={translateMeConfiguration}
      theirLang={translateMeConfiguration.languageConfig.theirLanguage}
      myLang={translateMeConfiguration.languageConfig.myLanguage}
      key={`modal-${resetModal}`}
    />
  );

  return [requestTranslation, translationModal] as const;
};
export default useTranslation;
